import React, { useEffect, useState } from 'react';
import {
	Flex,
	IconButton,
	Image,
	Input,
	InputGroup,
	InputLeftElement,
	InputRightElement,
} from '@chakra-ui/react';
import TrPrimaryButton from '../TrPrimaryButton';
import { pixels } from '../../theme';
import { useLocation, useNavigate } from 'react-router-dom';

interface SearchInputProps {
	placeholder?: string;
	onSearch?: (value: string) => void;
	height?: any | Record<any, any>;
	minW?: string;
	maxW?: string;
	borderRadius?: string;
	bg?: any;
	boxShadow?: string;

	onFocus?: any;
	onBlur?: any;

	inputFontColor: any;

	dontDoInitSearch?: boolean;

	onClose?: () => void;
}

const SearchInput: React.FC<SearchInputProps> = ({
	placeholder = 'Where would you like to visit',
	onSearch,
	height = { 'base': '44px', 'lg': '55px', '2xl': '64px' },
	maxW = { 'base': '894px', 'lg': '894px', '2xl': '1100px' },
	borderRadius = '12px',
	bg,
	boxShadow = '0px 7px 63px 0px rgba(0, 0, 1, 0.99)',
	onBlur,
	onFocus,
	inputFontColor,
	dontDoInitSearch,
	onClose,
}) => {
	const navigate = useNavigate();
	const location = useLocation();
	const [searchValue, setSearchValue] = useState(placeholder);
	useEffect(() => {
		setSearchValue(placeholder);
	}, [placeholder]);
	const handleFocus = () => {
		if (onFocus) {
			onFocus();
		}
	};

	const handleBlur = () => {
		if (onBlur) {
			onBlur();
		}
	};

	const handleSearch = () => {
		navigate('/search');
		if (onSearch) {
			onSearch(searchValue);
		}
	};

	const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') {
			handleSearch();
		}
	};

	return (
		<Flex
			borderRadius={borderRadius}
			bg={bg}
			boxShadow={boxShadow}
			justifyContent='flex-end'
			alignItems='center'
			padding='0'
			position={'fixed'}
			height={height}
			ml={'10px'}
			w={{
				'base': '85%',
				'lg': pixels['894pixels'],
				// 'xl': '1400pixels',
				'2xl': pixels['1100pixels'],
			}}
			maxW={maxW}
			sx={{
				justifyContent: 'center',
				alignItems: 'center',
			}}>
			<InputGroup
				display={'flex'}
				width='100%'
				justifyContent={'center'}
				alignItems={'center'}
				p={0}
				height={'100%'}
				position={'relative'}>
				<Input
					fontSize={{ base: '16px', lg: 'inherit' }}
					colorScheme='primary'
					color={inputFontColor}
					w='100%'
					maxW={maxW}
					py={0}
					px={{ base: pixels['5pixels'], lg: pixels['20pixels'] }}
					mx={0}
					type='text'
					border='none'
					placeholder={placeholder || 'Top places in Vietnam'}
					value={searchValue}
					onChange={(e) => setSearchValue(e.target.value)}
					onFocus={handleFocus}
					onBlur={handleBlur}
					onKeyPress={handleKeyPress}
					borderRadius={borderRadius}
					bg={bg}
					height='100%'
					display={'flex'}
					justifyContent={'center'}
					alignItems={'center'}
					_placeholder={{
						color: inputFontColor,
						fontFamily: 'Poppins',
						fontSize: { 'base': '13px', 'lg': '13px', '2xl': '16px' },
						fontStyle: 'normal',
						fontWeight: 500,
						lineHeight: '18px',
					}}
				/>
				{location.pathname === '/' ? (
					<InputLeftElement display={{ base: 'flex', lg: 'none' }} top={'3px'}>
						<Image src='/icons/search.svg' alt='search for places' />
					</InputLeftElement>
				) : (
					<InputLeftElement
						mt={`-4px`}
						display={{ base: 'flex', lg: 'none' }}
						top={'3px'}>
						<IconButton
							onClick={() => {
								if (dontDoInitSearch) {
									//@ts-ignore
									return onClose();
								}
								navigate('/');
							}}
							background={'transparent'}
							_active={{ background: 'none' }}
							_hover={{ background: 'none' }}
							outline={'none'}
							display={{ base: 'flex', lg: 'none' }}
							top={'3px'}
							left={'-35px'}
							aria-label={''}>
							<Image src='/icons/Back_Icon_White.svg' alt='search for places' />
						</IconButton>
					</InputLeftElement>
				)}
				<InputRightElement
					position='absolute'
					top='50%'
					left='95%'
					transform='translate(-50%, -57%)'
					w='min-content'
					pr='9px'
					display={{ base: 'none', lg: 'flex' }}
					justifyContent={'center'}
					margin={'auto'}
					alignItems={'center'}>
					<TrPrimaryButton
						w={{ 'base': '81px', '2xl': pixels['100pixels'] }}
						fontSize={{
							'base': pixels['10pixels'],
							'lg': pixels['12pixels'],
							'2xl': pixels['16pixels'],
						}}
						display='flex'
						margin={'auto'}
						justifyContent={'center'}
						mt={{ 'base': 0, 'lg': '3px', '2xl': 0 }}
						mr='9px'
						aria-label='Search'
						onClick={handleSearch}
						height={{ 'base': '39px', 'lg': '39px', '2xl': '48px' }}>
						Search
					</TrPrimaryButton>
				</InputRightElement>
			</InputGroup>
		</Flex>
	);
};

export default SearchInput;
