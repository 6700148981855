import {
	Avatar,
	Box,
	Flex,
	Image,
	Text,
	Tooltip,
	useDisclosure,
	Button,
} from '@chakra-ui/react';
import { pixels } from '../../theme';
import TrPrimaryButton from '../TrPrimaryButton';
import TrOutlineButton from '../TrOutlineButton';
import TrCircleButton from '../TrCircleButton';
import React, {
	ReactNode,
	useCallback,
	useContext,
	useRef,
	useState,
} from 'react';
import useAxios from '../../hooks/useAxios';
import ModalWithBackdrop from '../ModalWithBackdrop';
import SigninComponent from '../SigninComponent';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../context/AppContext';
import TrPopOverMap from '../TrPopOverMap';

interface TopHighlightItemProps {
	onOpenMobileMap?: (index: number) => void;
	isLast?: boolean;
	previewImage: string;

	city: string;

	country: string;

	video_url?: string;

	url: string;

	title?: string;

	best_title?: string;

	best_value?: any;

	description?: string;

	vibe?: string;

	best_time_to_visit?: string;

	tags?: any;

	highLightIndex: number;
	onOpenDetailsModal: () => void;

	location?: { lat: number; lng: number };

	highlights?: [];
}
interface HighlightTitleProps {
	title: string;

	description: string | undefined;
}

const HighlightTitle: React.FC<HighlightTitleProps> = ({
	title,
	description,
}) => {
	const textRef = useRef<HTMLDivElement>(null);

	return (
		<Tooltip label={`${title} - ${description}`} hasArrow>
			<Text
				ref={textRef}
				sx={{
					color: 'white',
					fontFamily: 'Poppins',
					fontSize: { 'base': '11px', '2xl': '14px' },
					fontWeight: 500,
					lineHeight: { 'base': '110%', '2xl': '130%' },
				}}
				noOfLines={1}>
				<span style={{ fontWeight: 800 }}>{title}</span> -{' '}
				<span style={{ color: 'rgba(255, 255, 255, 0.63)' }}>
					{description}
				</span>
			</Text>
		</Tooltip>
	);
};

const myAppUrl = process.env.REACT_APP_URL;

const TopHighlightItem = (props: TopHighlightItemProps) => {
	console.log('location', props);
	const { setVideoHighlightDetailLoading }: any = useContext(AppContext);
	const [showAddedToBucket, setShowAddedToBucket] = useState(false);
	interface TrCircleProps {
		children: ReactNode;
	}
	console.log('propss', props);
	const TrCircle: React.FC<TrCircleProps> = ({ children }) => {
		return (
			<TrCircleButton
				w={pixels['28pixels']}
				h={pixels['28pixels']}
				minW={pixels['28pixels']}
				background={'rgba(2, 6, 12, 0.33)'}
				backdropFilter='blur(2px)'
				aria-label='circle button'>
				{children}
			</TrCircleButton>
		);
	};

	const { fetchData } = useAxios();
	const { fetchData: fetchHighlightDetail } = useAxios();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const navigate = useNavigate();
	const {
		currentVideoIndex,
		likeStatus,
		setLikeStatus,
		shareModal,
		setVideoHighlightDetail,
		likeEntities,
		setLikeEntities,
		ip,
		searchDetail,
	}: any = useContext(AppContext);

	const handleShareClick = useCallback(
		(title?: any, url?: any) => {
			shareModal.handleShareClick(encodeURI(url), title);
		},
		[shareModal],
	);

	const handleLike = async (e: any) => {
		e.stopPropagation();
		const token = localStorage.getItem('token');
		if (token) {
			console.log('this is props : ', props);
			const body = {
				previewImage: props?.previewImage,
				city: props?.city === '' ? undefined : props?.city,
				country: props?.country === '' ? undefined : props?.country,
				title: props?.title,
				tags: props?.tags,
				description: props?.description,
				url: props?.video_url,
			};

			if (!likeStatus[currentVideoIndex]?.highLights[props.highLightIndex]) {
				await fetchData({
					method: 'POST',
					url: 'user/likeEntity',
					options: {
						data: { ...body, token: localStorage.getItem('token') },
						headers: { 'Content-Type': 'application/json' },
					},
					auth: false,
				});
				let obj = likeStatus;
				obj[currentVideoIndex].highLights[props.highLightIndex] = true;
				setLikeStatus(obj);

				// adding like to current likeEntities context
				setLikeEntities([...likeEntities, body]);
				setShowAddedToBucket(true);

				setTimeout(() => {
					setShowAddedToBucket(false);
				}, 3000);
			} else {
				await fetchData({
					method: 'POST',
					url: 'user/dislikeEntity',
					options: {
						data: { ...body, token: localStorage.getItem('token') },
						headers: { 'Content-Type': 'application/json' },
					},
					auth: false,
				});
				let obj = likeStatus;
				obj[currentVideoIndex].highLights[props.highLightIndex] = false;
				setLikeStatus(obj);

				// removing the entity from current likeEntities
				const tempLikeEntities = [];
				for (let entity of likeEntities) {
					if (!(entity['title'] === props.title)) {
						tempLikeEntities.push(entity);
					}
				}
				setLikeEntities(tempLikeEntities);
			}
		} else {
			onOpen();
		}
	};

	const fetchHighlightDetailMethod = async () => {
		setVideoHighlightDetailLoading(true);
		const { data: fake_data } = await fetchHighlightDetail({
			method: 'GET',
			url: `ai/place-info`,
			options: {
				params: {
					place_name: `${props.title + ', ' + props?.country}`,
					latitude: props?.location?.lat,
					longitude: props?.location?.lng,
					user_ip: ip,
				},

				headers: { 'Content-Type': 'application/json' },
			},
			auth: false,
		});
		setVideoHighlightDetail(fake_data);
		setVideoHighlightDetailLoading(false);
		console.log('fake data', fake_data);
	};
	return (
		<>
			{/* desktop */}
			<Box
				visibility={props.title ? 'visible' : 'hidden'}
				onClick={() => {
					fetchHighlightDetailMethod();
					props.onOpenDetailsModal();
				}}
				maxWidth={{ 'md': '150px', 'lg': '180px', '2xl': '246px' }}
				width={{ 'md': '150px', 'lg': '180px', '2xl': '246px' }}
				display={{ base: 'none', md: 'block', lg: 'block' }}>
				<Flex
					key={props.title}
					position={'relative'}
					width={{ 'lg': '180px', '2xl': '246px' }}
					height={{ 'lg': '113px', '2xl': '110px' }}
					flexShrink='0'>
					<ModalWithBackdrop
						isOpen={isOpen}
						onClose={() => {
							onClose();
							navigate('/');
						}}>
						<SigninComponent onClose={onClose} />
					</ModalWithBackdrop>
					<Flex
						flexDir={'column'}
						gap={{ 'base': pixels['13pixels'], '2xl': '17px' }}>
						<Avatar
							borderRadius={{
								'base': pixels['16pixels'],
								'2xl': pixels['20pixels'],
							}}
							w={'100%'}
							h={{
								'base': pixels['113pixels'],
								'2xl': pixels['140pixels'],
							}}
							src={props?.previewImage}
							name={props?.title}
						/>
						<Flex
							position='absolute'
							top='6px'
							right='6px'
							zIndex={3000}
							gap={pixels['12pixels']}>
							<TrCircle aria-label='like'>
								{likeStatus[currentVideoIndex]?.highLights[
									props.highLightIndex
								] === false ? (
									<Image
										w={pixels['14pixels']}
										h={pixels['14pixels']}
										src='/icons/heart-white.svg'
										alt='like'
										onClick={handleLike}
									/>
								) : (
									<Image
										w={pixels['14pixels']}
										h={pixels['14pixels']}
										src='/icons/like.svg'
										alt='like'
										onClick={handleLike}
									/>
								)}
							</TrCircle>
							<TrCircle aria-label='share'>
								<Image
									onClick={(e) => {
										console.log('app url', myAppUrl);
										e.stopPropagation();
										const title = searchDetail?.videos[currentVideoIndex]?.title
											? searchDetail?.videos[currentVideoIndex]?.title
											: searchDetail?.videos[currentVideoIndex]?.video_play_url
												?.title;
										const link = `${myAppUrl}/search?query=${title}`;
										console.log('link', link);

										handleShareClick(title, link);
									}}
									w={pixels['14pixels']}
									h={pixels['14pixels']}
									src='/icons/share2.svg'
									alt='share'
								/>
							</TrCircle>
						</Flex>
						<Flex
							w={'100%'}
							gap={pixels['5pixels']}
							justifyContent={'space-between'}>
							<Text
								color='rgba(255, 255, 255, 0.93)'
								fontFamily='Poppins'
								fontSize={{ 'base': '13px', 'lg': '10px', '2xl': '16px' }}
								fontStyle='normal'
								fontWeight={{ 'base': '600', '2xl': '800' }}
								my='auto'
								lineHeight='115%'>
								{props?.title}
							</Text>
							<Box
								onClick={(e) => {
									e.stopPropagation();
								}}
								// position={'relative'}
								zIndex={9000000000000000000}>
								{props?.location && (
									<TrPopOverMap
										//@ts-ignore
										shiftLeft={props?.isLast}
										location={props?.location}
										title={props.title}
										previewImage={props.previewImage}
									/>
								)}
							</Box>
						</Flex>
						<Text
							minH={pixels['55pixels']}
							color='rgba(255, 255, 255, 0.63)'
							fontFamily='Poppins'
							fontSize={{
								'base': pixels['11pixels'],
								'md': pixels['10pixels'],
								'lg': pixels['10pixels'],
								'2xl': pixels['14pixels'],
							}}
							fontStyle='normal'
							fontWeight='500'
							my='auto'
							noOfLines={3}
							lineHeight={{ 'base': '110%', '2xl': '135%' }}>
							{props?.description}
						</Text>
						<Flex
							maxW={'100%'}
							flexDir={'column'}
							gap={{ 'base': '3px', '2xl': '4px' }}>
							<HighlightTitle
								title='Vibe'
								description={props?.tags?.join(', ') || 'N/A'}
							/>
							<HighlightTitle
								title={
									props?.best_title
										?.replace(/_/g, ' ')
										.replace(/\b\w/g, (char) => char.toUpperCase()) || ''
								}
								description={
									props?.best_value ? props?.best_value?.join(', ') : ''
								}
							/>
						</Flex>
						<Flex gap={'8px'}>
							<TrOutlineButton
								w={'50%'}
								h={{
									'base': pixels['27pixels'],
									'2xl': pixels['34pixels'],
								}}
								fontSize={{
									'base': pixels['10pixels'],
									'2xl': '13px',
								}}>
								Details
							</TrOutlineButton>{' '}
							<TrPrimaryButton
								width={'50%'}
								h={{
									'base': pixels['27pixels'],
									'2xl': pixels['34pixels'],
								}}
								fontSize={{
									'base': pixels['10pixels'],
									'2xl': '13px',
								}}>
								Book
							</TrPrimaryButton>{' '}
						</Flex>
					</Flex>
				</Flex>
			</Box>
			{/* mobile */}
			<Box display={{ base: 'block', md: 'none', lg: 'none' }}>
				<Flex
					key={props.title}
					position={'relative'}
					width='100%'
					flexShrink='0'
					flexDir={'column'}
					gap={{ 'base': '11px', '2xl': '13px' }}>
					<Avatar
						w={'100%'}
						h={'130px'}
						borderRadius={pixels['20pixels']}
						src={props?.previewImage}
						name={props?.title}
					/>

					<Flex
						position='absolute'
						top='6px'
						right='6px'
						gap={pixels['5pixels']}>
						<TrCircle aria-label='like'>
							{likeStatus[currentVideoIndex]?.highLights[
								props.highLightIndex
							] === false ? (
								<Image
									w={pixels['14pixels']}
									h={pixels['14pixels']}
									src='/icons/heart-white.svg'
									alt='like'
									onClick={handleLike}
								/>
							) : (
								<Image
									w={pixels['14pixels']}
									h={pixels['14pixels']}
									src='/icons/like.svg'
									alt='like'
									onClick={handleLike}
								/>
							)}
						</TrCircle>
						<TrCircle aria-label='share'>
							<Image
								onClick={(e) => {
									console.log('app url', myAppUrl);
									e.stopPropagation();
									const title = searchDetail?.videos[currentVideoIndex]?.title
										? searchDetail?.videos[currentVideoIndex]?.title
										: searchDetail?.videos[currentVideoIndex]?.video_play_url
											?.title;
									const link = `${myAppUrl}/search?query=${title}`;
									console.log('link', link);

									handleShareClick(title, link);
								}}
								w={pixels['14pixels']}
								h={pixels['14pixels']}
								src='/icons/share2.svg'
								alt='share'
							/>
						</TrCircle>
					</Flex>
					<Flex justifyContent={'space-between'}>
						<Text
							color='rgba(255, 255, 255, 0.93)'
							fontFamily='Poppins'
							fontSize='14px'
							fontWeight='800'
							my='auto'
							lineHeight='125%'
							flex={4}>
							{props?.title}
						</Text>
						<Box
							onClick={() => {
								if (props.onOpenMobileMap) {
									props?.onOpenMobileMap(props.highLightIndex);
								}
							}}
							sx={{
								borderRadius: '50%',
								background: 'rgba(255, 255, 255, 0.13)',
								backdropFilter: 'blur(2px)',
								h: '25px',
								w: '25px',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}>
							<Image src='/icons/map_pin.svg' alt='map_pin' />
						</Box>
					</Flex>
					<Text
						minH={pixels['55pixels']}
						color='rgba(255, 255, 255, 0.63)'
						fontFamily='Poppins'
						fontSize={pixels['14pixels']}
						fontStyle='normal'
						fontWeight='500'
						my='auto'
						lineHeight='135%'
						noOfLines={2}>
						{props?.description}
					</Text>
					<Flex flexDir={'column'} gap={'10px'}>
						<HighlightTitle
							title='Vibe'
							description={props?.tags?.join(', ') || 'N/A'}
						/>
						<HighlightTitle
							title={
								props?.best_title
									?.replace(/_/g, ' ')
									.replace(/\b\w/g, (char) => char.toUpperCase()) || ''
							}
							description={
								props?.best_value ? props?.best_value?.join(', ') : ''
							}
						/>
					</Flex>
					<Flex gap={'8px'}>
						<TrOutlineButton w={'50%'} h={pixels['34pixels']} fontSize={'13px'}>
							Details
						</TrOutlineButton>{' '}
						<TrPrimaryButton w={'50%'} h={pixels['34pixels']} fontSize={'13px'}>
							Book
						</TrPrimaryButton>{' '}
					</Flex>
				</Flex>
			</Box>
			{showAddedToBucket && (
				<Flex
					position='fixed'
					bottom='10px'
					left='50%'
					transform='translateX(-50%)'
					bg='#AE7CFF'
					w={'300px'}
					h={'40px'}
					color='white'
					borderRadius='8px'
					alignItems={'center'}
					justifyContent={'space-around'}
					zIndex={200}>
					<Text
						fontFamily='Poppins'
						fontSize='18px'
						fontWeight={'medium'}
						lineHeight='19px'
						letterSpacing='-0.5611507892608643px'
						textAlign='left'>
						Added to bucket list
					</Text>
					<Button
						variant={'ghost'}
						color={'white'}
						_hover={{ bg: 'none' }}
						fontWeight={'medium'}
						onClick={() => navigate('/navigate')}>
						View
					</Button>
				</Flex>
			)}
		</>
	);
};

export default TopHighlightItem;
