import { useState} from "react";
import {
  SimpleGrid,
  useDisclosure,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";

import MapComponent from "../TrMapLeaf/TrMapLeaflet";
import React from "react";
import VideoHighlightsCard from "./VideoHighlightsCard";

interface VideoHighlightsItemProps {
  highlights?: any[];
}
// const highlights = [
//     {
//         best: {
//             best_time_to_visit: ["Summer", "Fall"],
//         },
//         city: "La Veta",
//         continent: null,
//         country: "United States",
//         description:
//             "A small town described as a hidden gem, where the group camped in a brewery parking lot after charming the staff.",
//         dynamic_fields: {
//             best_time_to_visit: "",
//             duration: "",
//             nearby_amenities: "Brewery",
//             what_to_bring: ["Camping gear"],
//         },
//         identity_tag: "",
//         image:
//             "https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photoreference=AdCG2DPx0r6b--PE20sqDqgpEpd6xP6-WG3BCj2VG0b3WhfiXlb_GbyXzbtsyqmm2SKEBe4EowH9EGvl0wbxvGzHKINuxtRLX837MbGkYltpTHXIhIeejur9iGV1KubZxikMfWAJs9-RR5CYe2m3J8j-v3XfiSU5wqwXcRoPOPxze3XwfE7Q&key=AIzaSyCzXq7YDZ6T1d-92O39HHyyAuapVRE3two",
//         location: {
//             lat: 54.5259614,
//             lng: -105.2551187,
//         },
//         tags: ["La Veta", "Colorado", "hidden gem", "brewery"],
//         title: "La Veta, Colorado",
//     },
//     {
//         best: {
//             best_to_do: [
//                 "Explore rural roads",
//                 "Visit local towns",
//                 "Enjoy the scenery",
//             ],
//         },
//         city: "",
//         continent: null,
//         country: "United States",
//         description:
//             "A group of friends riding a three-seater bike, called a Trandom, across the United States from Oceanside, California to Annapolis, Maryland. They aim to cover 3,000 miles in just over a month.",
//         dynamic_fields: {
//             best_time_to_visit: "",
//             duration: "Over a month",
//             nearby_amenities: "",
//             what_to_bring: ["Bike gear", "Helmet", "Snacks"],
//         },
//         identity_tag: "",
//         image:
//             "https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photoreference=AdCG2DOxEEIkFWs-pcU5cW1rqcjQ87E5sYY9grTWxcy1yx3K7lZYijKYwlam3PLsmNnfFXvLwi8eztUkrWY8iv5hMU5sVuf--95ttvUjMbG-ue0V4E-aMwQB7QN5Y9JwrRv4a2G_3nn7YuOw6gV8Yrl3f0xCX_e02cxgEON5LWdGJYhYcCnf&key=AIzaSyCzXq7YDZ6T1d-92O39HHyyAuapVRE3two",
//         location: {
//             lat: 54.5259614,
//             lng: -105.2551187,
//         },
//         tags: ["bike journey", "Trandom", "cross-country", "adventure"],
//         title: "Trandom Bike Journey",
//     },
//     {
//         best: {
//             best_to_do: ["Camping", "Hiking", "Fishing"],
//         },
//         city: "",
//         continent: null,
//         country: "",
//         description:
//             "A campground featuring a playground and two toilets, mentioned humorously for potential bug issues. It is described as boring without certain friends present.",
//         dynamic_fields: {},
//         identity_tag: "",
//         image:
//             "https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photoreference=AdCG2DM6aHzHWNCsMKT84mmVT4bKk2b9Pfv1l2n31jCEaiHWjC_3NwE_plHdpSJJiNDzKv9IP54E5SOQTVUEAJhufj19-MKa9Ga0Bp9LR6_02H1OdKaDXOp8nh4ZGqDPnjPGZ3UkAAngybDdRj1rj5ovgizr68-xHPHFWFzpm83I6Wi0vukR&key=AIzaSyCzXq7YDZ6T1d-92O39HHyyAuapVRE3two",
//         location: {
//             lat: 0.0,
//             lng: 0.0,
//         },
//         tags: ["campground", "playground", "toilets", "bugs"],
//         title: "Parrot Cove Campground",
//     },
//     {
//         best: {
//             best_time_to_visit: ["Spring", "Fall"],
//         },
//         city: "Pratt",
//         continent: null,
//         country: "United States",
//         description:
//             "The halfway point of a couple's journey across the United States in a van, marking a significant milestone in their adventure.",
//         dynamic_fields: {
//             best_time_to_visit: "",
//             duration: "",
//             nearby_amenities: "",
//             what_to_bring: ["Van essentials"],
//         },
//         identity_tag: "",
//         image:
//             "https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photoreference=AdCG2DNaXv41QNOBz5ZirGbvf-Qq3M8mZKuDzRi1XYJhWlH8bxwDk4uanPuIbpJAI6nRbBRFLmLKwdLunxRcUm8ha2ZSVrJR83-6V_j-K7LWpELfNy4Sh4C2zJoD1cmVMMwlOXiiGgDBwKDDDGuwWRzd9Exs5yWzPitxURzbrV4uf4hj8CU&key=AIzaSyCzXq7YDZ6T1d-92O39HHyyAuapVRE3two",
//         location: {
//             lat: 54.5259614,
//             lng: -105.2551187,
//         },
//         tags: ["Pratt", "Kansas", "halfway point", "van life"],
//         title: "Pratt, Kansas",
//     },
//     {
//         best: {
//             best_to_do: ["Biking", "Hiking", "Horseback Riding"],
//         },
//         city: "",
//         continent: null,
//         country: "United States",
//         description:
//             "A 300-mile bike path running parallel to the group's route, offering a peaceful ride free from cars and traffic.",
//         dynamic_fields: {
//             best_time_to_visit: "",
//             duration: "",
//             nearby_amenities: "",
//             what_to_bring: ["Bike gear", "Water"],
//         },
//         identity_tag: "",
//         image:
//             "https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photoreference=AdCG2DNvx9V3K96Jf6EfKU_WyP6e58VE7cQ6FffPuLFHngDIf12NxXPrPF-NkWSDY26p0JEgIJWkxdH-ZJHrY1GaN4IrdOUU0CdU1ZYqEMPSbpxYfyWPhvqUwRxh8xP29o7GXnohDkfCVpGbSjNIgMflWCL1lW2ljAd3gQ8HleMPo_lz9rJ6&key=AIzaSyCzXq7YDZ6T1d-92O39HHyyAuapVRE3two",
//         location: {
//             lat: 54.5259614,
//             lng: -105.2551187,
//         },
//         tags: ["Katy Trail", "bike path", "Missouri", "cycling"],
//         title: "Katy Trail",
//     },
// ];
export const VideoHighlights: React.FC<VideoHighlightsItemProps> = ({
  highlights,
}) => {
  // export const VideoHighlights:React.FC=()=>{
  const [highLightIndex, setHighlightIndex] = useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleMap = (index: React.SetStateAction<number>) => {
    setHighlightIndex(index);
    onOpen();
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent zIndex={9999999} mt={"0"} p={0} height={"100%"}>
          <ModalBody p={0} m={0} height={"100%"}>
            <MapComponent
              showCloseButton={true}
              defaultIndex={highLightIndex}
              defaultFullscreen={true}
              // isMobile={true}
              dismissMap={() => {
                console.log("ok");
                onClose();
              }}
              marks={
                highlights
                  ? highlights?.map((highlight: any) => ({
                      latitude:
                        highlight &&
                        highlight?.location &&
                        isNaN(highlight?.location)
                          ? parseFloat(highlight?.location?.lat)
                          : 0,
                      longitude:
                        highlight &&
                        highlight?.location &&
                        isNaN(highlight?.location)
                          ? parseFloat(highlight?.location?.lng??highlight?.location?.long) 
                          : 0,
                      title: highlight?.title,
                      previewImage: highlight?.image,
                    }))
                  : []
              }
            />
          </ModalBody>
        </ModalContent>
      </Modal>
      <>
        <SimpleGrid columns={2} spacing={5} rowGap={8}>
          {highlights?.map((highlight: any, index: number) => (
            <VideoHighlightsCard
              handleMap={() => handleMap(index)}
              // likedhighlights={likedhighlights}
              highlight={highlight}
            />
          ))}
        </SimpleGrid>
      </>
    </>
  );
};
