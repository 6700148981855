import {
    Box,
    Image,
    Flex,
    Heading,
    Text,
    Textarea,
    FormControl,
    Button,
} from "@chakra-ui/react";
import { useState } from "react";


const ContactUs: React.FC = () => {

    const [tripDetails, setTripDetails] = useState("");

    const handleSend = async () => {
        if (!tripDetails.trim()) {
            alert("Please provide trip details before sending.");
            return;
        }
    };
    
    return (
        <Box
            width="100%"
            height="100vh"
            backgroundImage="/images/bg_images/Bg_4.png"
            position="relative"
            overflow="hidden"
            backgroundSize="cover"
            backgroundPosition="center"
            backgroundRepeat="no-repeat"
            p={5}
        >
            <Flex mt={"10%"} mb={"10"} direction="column" gap={0} align={"center"}>
                <Flex direction="row" align={"center"} gap={4}>
                    <Image src="/icons/logo.svg" alt="logo" boxSize={"30px"} />
                    <Text
                        bgGradient="linear-gradient(90deg, #9BA1F2 0%, #F9EEC5 100%)"
                        bgClip={"text"}
                        fontSize={"26px"}
                        fontWeight={700}
                    >
                        Traverse AI
                    </Text>
                </Flex>
                <Text fontWeight={500} fontSize={"11px"} color={"#FFFFFFA1"} mt={"2px"}>
                    Start your journey with our specialised advisors
                </Text>

            </Flex>
            <Flex color={"#FFFFFFD0"} flexDir={"column"} gap={10}>
                <Flex flexDir={'column'} gap={3}>
                    <Heading fontSize={"18px"} mb={4} textAlign={'center'}>

                        Let us help you turn your travel ideas into a tailored plan
                    </Heading>
                    <Text
                        fontWeight={500}
                        fontSize={"15px"}
                        color={"#FFFFFFD1"}
                        mt={"2px"}
                        textAlign={"justify"}
                    >
                        Option 1: Drop Us a Message
                    </Text>
                    <Text
                        fontWeight={500}
                        fontSize={"12px"}
                        color={"#FFFFFFD1"}
                        textAlign={"justify"}
                    >
                        Prefer to write down your travel ideas? Share the
                        details, and we’ll get back to you with a customised itinerary.
                    </Text>
                    <FormControl>
                        <Text
                            fontWeight={500}
                            fontSize={"12px"}
                            color={"#FFFFFFD1"}
                            textAlign={"justify"}
                            mb={3}
                        >
                            Tell us about your trip!
                        </Text>
                        <Textarea
                            fontSize={"13px"}
                            textAlign={"left"}
                            p={3}
                            color={"#FFFFFFC1"}
                            border={"1px solid grey"}
                            placeholder="Example: 'We’re planning a 10-day trip to Italy in July. Looking for a mix of cultural experiences, local food tours, and beach relaxation.' "
                            value={tripDetails}
                            onChange={(e) => setTripDetails(e.target.value)}
                        />

                        <Button
                            fontSize={['15px', "19px", "23px"]}
                            mt={'4%'}
                            w={'30%'}
                            py={"20px"}
                            bg={"linear-gradient(90deg, #ACAFEB 0%, #E9E1CD 100%)"}
                            borderRadius={'100px'}
                            onClick={handleSend}
                        >
                            Send
                        </Button>
                    </FormControl>
                </Flex>
                <Flex flexDir={'column'} gap={3} mt={-3}>
                    <Text
                        fontWeight={500}
                        fontSize={"15px"}
                        color={"#FFFFFFD1"}
                        mt={"2px"}
                        textAlign={"justify"}
                    >
                        Option 2: Schedule a Call
                    </Text>
                    <Text
                        fontWeight={500}
                        fontSize={"12px"}
                        color={"#FFFFFFD1"}
                        mt={"2px"}
                        textAlign={"justify"}
                    >
                        Book a one-on-one session with our travel experts. We’ll walk you through the planning process, answer your questions, and craft the ideal travel plan
                    </Text>
                    <Button
                        fontSize={['15px', "19px", "23px"]}

                        w={'55%'}

                        py={"20px"}
                        bg={"linear-gradient(90deg, #ACAFEB 0%, #E9E1CD 100%)"}
                        borderRadius={'100px'}
                        onClick={()=>{
                            window.open("https://calendly.com/requests-traverseai/30min", "_blank","noopener,noreferrer");
                        }}
                    >
                        Schedule a Call
                    </Button>
                </Flex>
            </Flex>

        </Box>
    );
};

export default ContactUs;
