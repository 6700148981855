import {
  Box,
  Flex,
  VStack,
  Button,
  Text,
  HStack,
  IconButton,
  Image,
  useDisclosure,
  Spinner,
} from "@chakra-ui/react";
import { keyframes } from "@emotion/react";
import { useState, Suspense, useEffect, useContext, useCallback } from "react";
import InfluencerId from "./InfluencerId";
import { BiSolidDislike } from "react-icons/bi";
import InfLocationDetail from "./InfLocationDetail";
import ReactPlayer from "react-player";

import { ToastBucketList } from "../ToastBucketList";
import ModalWithBackdrop from "../ModalWithBackdrop";
import SigninComponent from "../SigninComponent";
import useAxios from "../../hooks/useAxios";
import { AppContext } from "../../context/AppContext";
import { Link as ScrollLink, Element } from 'react-scroll';
import ToggleTip from "../ToggleTip/ToggleTip";

const moveUpDown = keyframes`
  0% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0); }
`;

interface InfluencersVideoProps {
  influencers: any;
  currentIndex: number;
  handleNext: () => void;
  handlePrevious: () => void;
  isLink?: boolean;
}

const InfluencersVideo1: React.FC<InfluencersVideoProps> = ({
  influencers,
  currentIndex,
  handleNext,
  handlePrevious,
  isLink = false,
}) => {
  const myAppUrl = process.env.REACT_APP_URL;
  const context: any = useContext(AppContext);
  const { likeSearches, setLikeSearches, shareModal } = context;
  const { fetchData } = useAxios();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showScrollUp, setShowScrollUp] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const [showThumbnail, setShowThumbnail] = useState(true);
  const [playUrl, setPlayUrl] = useState("https://www.youtube.com/");
  const [thumbnail, setThumbnail] = useState("");
  const [isLiked, setIsLiked] = useState(false);
  const [showAddedToBucket, setShowAddedToBucket] = useState(false);


  const toShow = process.env.REACT_APP_ENV as string;

  useEffect(() => {
    if (influencers && influencers[currentIndex] && !isLink) {
      console.log("current index", currentIndex);
      setThumbnail(influencers[currentIndex]?.preview_image);
      setPlayUrl(influencers[currentIndex]?.video_url);

    }
    if (isLink) {
      setThumbnail(influencers[currentIndex]?.preview_image);
      setPlayUrl(influencers[currentIndex]?.video_play_url?.media_url);
    }

  }, [currentIndex, isLink, handleNext, handlePrevious, influencers]);

  const fetchVideo = async (video: string) => {
    console.log("passing this url", video);
    const { data, statusCode, error } = await fetchData({
      method: "POST",
      url: "v1/video_metadata",
      options: {
        data: {
          url: video,
          play_url_required: true,
        },
        headers: { "Content-Type": "application/json" },
      },
      auth: false,
    });
    if (statusCode === 200) {
      if (data) {
        setPlayUrl(data?.["play_url"]);
      }
    } else {
      console.log(error);
    }
  };

  const handleVideoClick = async (video: string) => {
    console.log("isPlaying", isPlaying);
    if (!isPlaying && !isLink && (playUrl === "https://www.youtube.com/" || playUrl === influencers[currentIndex]?.video_url)
      // && influencers[currentIndex]?.video_play_url?.media_url===""
  ) {
      setIsPlaying((prevState) => !prevState);
      await fetchVideo(video);
      setShowThumbnail(false);
    } else {
      setIsPlaying((prevState) => !prevState);
      setShowThumbnail(false);
    }
  };
  // useEffect(() => {
  // 	console.log("i was callllled")
  // 	setThumbnail(influencers[currentIndex]?.preview_image);
  // });

  //   const handlers = useSwipeable({
  //     onSwipedUp: handleSwipeUp,
  //     trackMouse: true,
  //   });

  const handleShareClick = useCallback(
    (title?: any, url?: any) => {
      shareModal.handleShareClick(encodeURI(url), title);
    },
    [shareModal]
  );

  useEffect(() => {
    setIsLiked(false);
    if (influencers.length > 0) {
      // Ensure influencers has content

      console.log(likeSearches);

      for (let video of likeSearches) {
        if (video["url"] === influencers[currentIndex]?.video_url) {
          setIsLiked(true);
        }
      }
    }
  }, [likeSearches, currentIndex, influencers]); // Add influencers to dependencies

  const likeVideo = async (isLiked: boolean) => {
    const token = localStorage.getItem("token");
    if (token) {
      const body = {
        title: influencers[currentIndex]?.title,
        city:
          influencers[currentIndex]?.city === ""
            ? undefined
            : influencers[currentIndex]?.city,
        countries: influencers[currentIndex]?.country ?? {},
        description: influencers[currentIndex]?.description,
        tags: influencers[currentIndex]?.tags,
        url: influencers[currentIndex]?.video_url,
        previewImage: influencers[currentIndex]?.preview_image,
        location: influencers?.location,
        flightStartingPrice: influencers[currentIndex]?.flightStartingPrice,
      };

      try {
        if (isLiked) {
          // Dislike the influencer
          await fetchData({
            method: "POST",
            url: "user/dislikeSearch",
            options: {
              data: { ...body, token },
              headers: { "Content-Type": "application/json" },
            },
            auth: false,
          });

          const tempLikeVideos = [];
          for (let video of likeSearches) {
            if (!(video["url"] === body.url)) {
              tempLikeVideos.push(video);
            }
          }
          setLikeSearches(tempLikeVideos);
        } else {
          // Like the influencer
          await fetchData({
            method: "POST",
            url: "user/likeSearch",
            options: {
              data: { ...body, token },
              headers: { "Content-Type": "application/json" },
            },
            auth: false,
          });
          setLikeSearches([...likeSearches, body]);
          setShowAddedToBucket(true);
          setTimeout(() => {
            setShowAddedToBucket(false);
          }, 3000);
        }
      } catch (error) {
        console.error("Error while liking/disliking the influencer:", error);
      }
    }
  };
  const handleLike = (isLiked: boolean) => {
    const token = localStorage.getItem("token");
    if (token) {
      likeVideo(isLiked);
    } else {
      onOpen();
    }
  };
  // if (!influencers || !influencers[currentIndex]) {
  //   return <Text>No influencer data available</Text>;
  // }

  // console.log(influencers[currentIndex]);
  const handleN = () => {
    setIsPlaying(false);
    setPlayUrl("https://www.youtube.com/");
    handleNext();
    setShowThumbnail(true);
    // setVideoUrl(influencers[currentIndex]?.video_url);
  };
  const handleP = () => {
    setIsPlaying(false);
    setPlayUrl("https://www.youtube.com/");
    handlePrevious();
    setShowThumbnail(true);
  };
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const viewportHeight = 30;

      // console.log(`Scroll Position: ${scrollPosition}`);
      // console.log(`Viewport Height: ${viewportHeight}`);

      if (scrollPosition > viewportHeight) {
        console.log('Hiding');
        setShowScrollUp(false);
      } else {
        console.log('Showing ');
        setShowScrollUp(true);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <>
      <ModalWithBackdrop isOpen={isOpen} onClose={onClose}>
        <SigninComponent onClose={onClose} />
      </ModalWithBackdrop>
      <Box overflowY={"auto"} overflowX={'hidden'}>
        <VStack
          id="story-container"
          width="100%"
          height="100vh"
          bg="black"
          overflowY="hidden"
          position={"relative"}
        >
          <Box
            position="absolute"
            width="100%"
            height="100%"
            top="0"
            left="0"
            zIndex={10} // Ensure it's above the children
            bgGradient="linear-gradient(180deg, #111417 0%, rgba(7, 9, 11, 0) 30%)"
            pointerEvents="none" // Prevent interaction with overlay
          />
          <Box
            position="absolute"
            width="100%"
            height="100%"
            top="0"
            left="0"
            zIndex={10} // Ensure it's above the children
            bgGradient="linear-gradient(0deg, #111417 7.37%, rgba(7, 9, 11, 0) 80%)"
            pointerEvents="none" // Prevent interaction with overlay
          />
          <Box position="absolute" top={0} left={0} right={0} bottom={0}>
            <ReactPlayer
              zIndex={-1}
              url={playUrl}
              light={showThumbnail ? thumbnail : false}
              playing={isPlaying}
              muted={false} // Mute the video
              loop={true} // Loop the video
              width="100%" // Full width
              height="100vh" // Full height
              // onClickPreview={(e) => {e.stopPropagation();}}
              onClick={() => {
                handleVideoClick(influencers[currentIndex]?.video_url);
              }}
              style={{ position: "absolute", top: 0, left: 0 }}
              config={{
                file: {
                  attributes: {
                    style: {
                      width: "100%",
                      height: "100vh",
                      objectFit: "cover",
                    },
                  },
                },
              }}
            />
            {isPlaying && (playUrl === "https://www.youtube.com/" || playUrl === influencers[currentIndex]?.video_url ) && (
              <Box
                position="absolute"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
                >
                <Spinner size="xl" color="rgba(255, 255, 255, 0.40)" />
              </Box>)}
            <IconButton
              aria-label="prev"
              icon={<Image src="/icons/left.svg" alt="like" />}
              variant="ghost"
              size="lg"
              position="absolute"
              top="50%"
              left="5%"
              transform="translateY(-50%)"
              borderRadius="full"
              _hover={{ bg: "transparent" }}
              _active={{ bg: "transparent" }}
              _focus={{ boxShadow: "none" }}
              _disabled={{ visibility: "hidden" }}
              isDisabled={currentIndex === 0}
              onClick={handleP}
            />

            <IconButton
              aria-label="next"
              icon={<Image src="/icons/right.svg" alt="like" />}
              variant="ghost"
              size="lg"
              position="absolute"
              top="50%"
              right="5%"
              transform="translateY(-50%)"
              borderRadius="full"
              _hover={{ bg: "transparent" }}
              _active={{ bg: "none" }}
              _focus={{ boxShadow: "none" }}
              _disabled={{ visibility: "hidden" }}
              isDisabled={currentIndex === influencers?.length - 1}
              onClick={handleN}
            />

            <VStack
              position="absolute"
              bottom={0}
              width="100%"
              left={0}
              zIndex={99}
              spacing={6}


            >
              <HStack
                alignItems={"center"}
                justifyContent={"space-between"}
                w={"100vw"}
                px={4}
                py={8}
                position={"relative"}
              >
                <Flex flexDir={"column"} my={4} alignItems={"flex-start"}>
                  <Box py={5}>
                    <InfluencerId infId={influencers?.[currentIndex]} />
                  </Box>
                  <Text
                    fontFamily="Poppins"
                    fontSize="20px"
                    fontWeight="700"
                    lineHeight="25px"
                    maxW={"330px"}
                    color={"#FFFFFFED"}
                    noOfLines={3}
                    letterSpacing="0.23455415666103363px"
                    textAlign="left"
                  >
                    {influencers[currentIndex]?.["title"]}
                  </Text>
                </Flex>
                <HStack spacing={0} zIndex={20}>
                  <IconButton
                    aria-label="Like"
                    icon={
                      <Image
                        src={
                          isLiked ? "/icons/like.svg" : "/icons/white-heart.svg"
                        }
                        alt="like"
                      />
                    }
                    variant="ghost"
                    size="lg"
                    borderRadius="full"
                    onClick={(e) => {
                      e.stopPropagation();

                      handleLike(isLiked);
                    }}
                    _hover={{ bg: "transparent" }}
                    _active={{ bg: "transparent" }}
                    _focus={{ boxShadow: "none" }}
                  />
                  <IconButton
                    aria-label="Share"
                    icon={<Image src="/icons/white-share.svg" alt="share" />}
                    variant="ghost"
                    size="lg"
                    borderRadius="full"
                    _hover={{ bg: "transparent" }}
                    _active={{ bg: "transparent" }}
                    _focus={{ boxShadow: "none" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      const title = influencers[currentIndex]?.["title"];
                      const link = `${myAppUrl}/video-detail?link=${influencers[currentIndex]?.["video_url"]}`;
                      handleShareClick(title, link);
                    }}
                  />
                </HStack>
              </HStack>
              {showScrollUp && (<HStack
                spacing={0}
                position={"absolute"}
                bottom={0}
                zIndex={11}
                justifyContent={"center"}
                animation={`${moveUpDown} 1s infinite`}
              >
                <Text fontSize="13px" color="white" fontWeight="500">
                  SCROLL UP FOR MORE
                </Text>
                <ScrollLink to="infLocationDetail" smooth={true} duration={500}>
                  <Button
                    bg="inherit"
                    variant="link"
                  //  onClick={handleSwipeUp}
                  >
                    <Image src="/icons/ArrowUp.svg" alt="swipe up" />
                  </Button>
                </ScrollLink>
              </HStack>)}


              {toShow === "DEV" && (<IconButton
                position={"absolute"}
                left={0}
                bottom={3}
                aria-label="info"
                mt={2}
                icon={<BiSolidDislike />}
                colorScheme="gray"
                onClick={() => { }

                }
                variant={"link"}
              />)}


            </VStack>
          </Box>
          <Box
            mt={2}
            position="absolute"
            right={0}
            bottom={3}
          >
            <ToggleTip link={influencers[currentIndex]?.video_url || '#'} />
          </Box>
        </VStack>
        <Box
          pt={"6"}
          bg="#000000"
          h={"100vh"}
          overscroll={"none"}
          overflowX={"hidden"}
        >
          <Suspense fallback={<div>Loading...</div>}>
            <Element name="infLocationDetail">
              <InfLocationDetail
                influencerData={influencers[currentIndex]}
              />
            </Element>
          </Suspense>
        </Box>
        {showAddedToBucket && <ToastBucketList />}
      </Box>
    </>
  );
};

export default InfluencersVideo1;
