import { Flex, Button, Image, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { fetchConversation } from '../../services/apiService'
import { useContext } from "react";
import { AppContext } from "../../context/AppContext";
// Define props interface
interface ConversationCardProps {
    title: string;
    image: string;
    conversation_id: string;
}

const ConversationCard: React.FC<ConversationCardProps> = ({ title, image, conversation_id }) => {
    const navigate = useNavigate();
    const context: any = useContext(AppContext);
    const { setChatEntries, setPreviousChatLoading, setCurrChatId, setTitle } = context;

    const handleOpenChat = () => {
        setChatEntries([]);
        setTitle(title);
        setPreviousChatLoading(true);
        setCurrChatId(conversation_id);
        navigate('/chat');
        const token = localStorage.getItem('token');
        if (token)
            fetchConversation(conversation_id, token, setChatEntries);
    }

    return (
        <Flex
            bg="#242127"
            p={3}
            borderRadius={'12px'}
            align="center"
            py={4}
            justify="space-evenly"
            w={'90vw'}
            mx={'5vw'}
            border={'1px solid #FFFFFF12'}
        >
            {/* Image and Title */}

            {/* {image ? ( */}
            <Image
                src={image === "" ? "/images/defaultConversation.png" : image} // Display the image if available
                boxSize="50px"
                rounded="md"
                border="1.06px solid #313030"
            />
            {/* ) : (
                <Box
                    width="50px"
                    height="50px"
                    borderRadius="8px"
                    backgroundColor="gray.600"
                    marginRight="12px"
                />

            )} */}


            <Text
                ml={2}
                mr={3}
                fontFamily="Poppins"
                fontSize="13px"
                w={'107.62px'}
                h={'max-content'}
                fontWeight="500"
                noOfLines={2}
                lineHeight="17px"
                letterSpacing="-0.465063214302063px"
                textAlign="left"
                color={'#FFFFFFBA'}
            >{title}
            </Text>

            {/* Open Chat Button */}
            <Button bg={'#2B2A2C'} borderRadius={'99px'} variant="solid" py={3} >
                <Text

                    fontFamily="Poppins"
                    fontSize="13px"
                    fontWeight="600"
                    lineHeight="26.94px"
                    onClick={handleOpenChat}
                    textAlign="left"
                    color={'#AE7CFF'}>
                    + Open Chat

                </Text>
            </Button>
        </Flex>
    );
};

export default ConversationCard;
