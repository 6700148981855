import { Box, Input, InputGroup, InputRightElement, SimpleGrid, Image } from "@chakra-ui/react";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import DestinationCard from "../components/Collection-flow/DestinationCard";

import { useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import { AppContext } from "../context/AppContext";
import { useLoading } from "../context/LoadingContext";

interface allEntity {
    title: string;
    image: string;
}

interface Story {
    title: string;
    city: string;
    country: string;
    continent: string;
    location: {
        lat: number;
        long: number;
    };
    description: string;
    image?: string[];
    videos?: string[];
    tags: string[];
    otherDetail?: any[];
}

const destinations = [
    {
        imageSrc: "/images/Iceland.png",
        subheadingText: "Northern lights spots",
        headingText: "Iceland",
        query: "Show me best spots to view Northern Lights in Iceland"
    },
    {
        imageSrc: "/images/st.png",
        subheadingText: "Best winter festivals",
        headingText: "Europe",
        query: "Best music or arts festivals in Europe in winter"
    },
    {
        imageSrc: "/images/Nightlife.png",
        subheadingText: "Best of nightlife",
        headingText: "Japan",
        query: "Best hidden, bespoke nightlife districts in Japan"
    },
    {
        imageSrc: "/images/Asia.png",
        subheadingText: "Luxury resorts in",
        headingText: "North India",
        query: "Find me the 6 most bespoke, hidden luxury resorts in North India"
    },

    {
        imageSrc: "/images/Switzerland.png",
        subheadingText: "Enchanting",
        headingText: "Switzerland",
        query: "Tell me the best romantic spots in Switzerland to experience during the winters"
    },
    {
        imageSrc: "/images/Greece.png",
        subheadingText: "Best Christmas markets in",
        headingText: "Europe",
        query: "Best Christmas markets in Europe this winter"
    }
];


const Collection: React.FC = () => {
    const appUrl=process.env.REACT_APP_SOCKET_URL;
    const navigate = useNavigate();
    const { setIsLoading } = useLoading();
    const context: any = useContext(AppContext);
    const { setIsCollection, setInfluencerData, setCollectionLoading } = context;
    const [searchTerm, setSearchTerm] = useState("");


    const convertToStories1 = (item: any): any => {
        return {
            title: item.title,
            city: item.city,
            country: item.country,
            continent: item.continent,
            location: item?.location ?? { lat: 0, long: 0 },
            description: item.description,
            image: item.image ? [item.image] : [""],
            videos: undefined,
            tags: item.tags,
            otherDetail: item.dynamic_fields
        };
    };
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };


    const handleCollectionCard = async (query: any) => {
        let allEntities: allEntity[] = [];
        let stories: Story[] = [];

        const socket = new WebSocket(appUrl+"v1/collection/stream");
        setIsCollection(true);
        setCollectionLoading(true);
        setIsLoading(true);
        socket.onopen = () => {
            console.log("WebSocket connection established");
            socket.send(JSON.stringify({ title: query }));
        };


        socket.onmessage = (event) => {
            const message = JSON.parse(event.data);
            console.log("Received message:", message);
            if (message.entities) {
                allEntities = message.entities;
                console.log("allEntities", allEntities);
            }
            if (message.entities_details) {
                stories = allEntities.map((entity) => {
                    const matchingDetail = message.entities_details.find(
                        (detail: any) => detail.title === entity.title
                    );
                    return matchingDetail ? convertToStories1(matchingDetail.entity_detail) : null;
                }).filter((detail) => detail !== null);
                console.log("stories", stories);

                setInfluencerData(Array(allEntities.length).fill([]));

                navigate("/story-carousel", {
                    state: {
                        clickedIndex: 0,
                        stories: stories,
                        allEntities: allEntities, // pass the array with title and image
                    },
                });
                setIsLoading(false);
            }
            if (message.search_details) {
                const inf = allEntities.map((entity) => {
                    const matchingDetail = message.search_details.find(
                        (detail: any) => detail.title === entity.title
                    );
                    return matchingDetail ? matchingDetail.search_detail : null;
                }).filter((detail) => detail !== null);
                setInfluencerData(inf);
                console.log("infl ", inf);
            }
            if (message.error) {
                console.log("Error", message.error);
                setIsLoading(false);
            }

            // Handle the received message here
        };

        socket.onclose = () => {
            setCollectionLoading(false);
            console.log("WebSocket connection closed");
        };

        socket.onerror = (error) => {
            console.error("WebSocket error:", error);
            setIsLoading(false);
        };

        return () => {
            socket.close();
        };
    };

    const handleSearch = () => {
        if (searchTerm.trim()) {
            handleCollectionCard(searchTerm );
        }
    };
    // const toShow = process.env.REACT_APP_ENV as string;
    return (
        <Box
            minH="100vh"
            w="100vw"
            overflow="hidden">

            <Box
                position="relative"
                zIndex={1}
                px="12px">
                {/* {toShow === "DEV" && */}
                <InputGroup mb={'4%'} w={'auto'} >
                    <Input
                        py={'3%'}
                        placeholder="Search"
                        _placeholder={{ color: "#FFFFFF" }}
                        bg="#242127"
                        color="#FFFFFF"
                        borderRadius="12px"
                        border={'1px solid #FFFFFF12'}
                        h={'48px'}
                        pl={5}
                        value={searchTerm} // Controlled input
                        onChange={handleInputChange}
                        onKeyDown={(e) => e.key === 'Enter' && handleSearch()} // Trigger search on Enter key press
                    />
                    <InputRightElement
                        py={'25px'}
                        mt={'1px'}
                        children={
                            <Image
                                boxSize={'18px'}
                                src={'/icons/search2.svg'}
                                alt="search"
                                cursor="pointer"
                                onClick={handleSearch} // Trigger search on icon click
                            />
                        }
                    />
                </InputGroup>
                {/* } */}
                <Box mb="20%">
                    <SimpleGrid columns={2} spacing={3} alignItems={'center'} justifyContent={'center'} >
                        {destinations.map((destination, index) => (
                            <DestinationCard
                                key={index}
                                onClick={() => handleCollectionCard(destination.query)}
                                imageSrc={destination.imageSrc}
                                headingText={destination.headingText}
                                subheadingText={destination.subheadingText}
                            />
                        ))}
                    </SimpleGrid>

                </Box>
            </Box>
        </Box>

    );
}

export default Collection;
