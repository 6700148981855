import { Input, InputGroup, InputRightElement, Image, Box } from "@chakra-ui/react";
import React, { useState } from "react";

interface SearchBarProps {
  onSearch: (searchTerm: string) => void;
}

const SearchBar: React.FC<SearchBarProps> = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [showSuggestedBox, setShowSuggestedBox] = useState(false);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value); // Update the state with the input value
  };

  const handleSearch = () => {
    if (searchTerm.trim()) {
      onSearch(searchTerm); // Trigger the search when Enter is pressed or icon is clicked
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const suggestions = [
    'Japan travel recommendations',
    'Solo female travel to Japan ',
    'New Zealand in December',
    'Top things to do in Thailand ',
    'Adventure activities in Switzerland',
  ];

  return (
    <Box>
      
      <InputGroup my={'1%'} w={'92vw'} onFocus={() => setShowSuggestedBox(true)} onBlur={() => setTimeout(() => setShowSuggestedBox(false), 200)}>
        <Input
          py={'3%'}
          placeholder="Search"
          _placeholder={{ color: "#FFFFFF" }}
          bg="#242127"
          color="#FFFFFF"
          borderRadius="12px"
          border={'1px solid #FFFFFF12'}
          h={'48px'}
          pl={5}
          value={searchTerm} // Controlled input
          onChange={handleInputChange}
          onKeyDown={handleKeyDown} // Trigger search on Enter key press
        />
        <InputRightElement
          py={'25px'}
          mt={'1px'}
          children={
            <Image
              boxSize={'18px'}
              src={'/icons/search2.svg'}
              alt="search"
              cursor="pointer"
              onClick={handleSearch} // Trigger search on icon click
            />
          }
        />
      </InputGroup>
      {showSuggestedBox && (
        <Box
        position={'absolute'}
          mt={2}
          bg="#242127"
          color="gray.200"
          w={'92vw'}
          borderRadius="12px"
          border="1px solid #FFFFFF12"
          maxH="auto"
          p={2}
          overflowY="auto"
        >
          {suggestions.map((suggestion, index) => (
            <Box
              key={index}
              p={2}
              fontSize={'md'}
              // _hover={{ bg: "#3a3a3a", cursor: "pointer" }}
              onClick={() => {
                setSearchTerm(suggestion);
                onSearch(suggestion);
              }}
            >
              {suggestion}
            </Box>
          ))}
        </Box>
      )}

    </Box>
  );
};

export default SearchBar;
