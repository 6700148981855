import { Box, Text, Heading, VStack, Image, Flex, HStack, Button } from "@chakra-ui/react";
import Header from "./Header"
import { useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';

const VoiceAdvisor: React.FC = () => {
    const navigate = useNavigate();
    useEffect(() => {

        const timer = setTimeout(() => {
            navigate('/chatbox1');
        }, 5000);
        return () => clearTimeout(timer);
    }, [navigate]);
    return (
        <Box>
            <Box
                position="fixed"
                top="0"
                left="0"
                width="100vw"
                height="100vh"
                bgColor="blackAlpha.600"  // Semi-transparent black background
                zIndex="overlay"         // Ensures it appears above other content
            />
            <Header
                backIconSrc="/icons/arrow_back.svg"
                chatIconSrc="/icons/chat_filled.svg"
                addIconSrc="/icons/plus_outline.svg"
                handleBackButton={() => { navigate(-1) }}
                chatButtonOnClick={() => { }}
                bgColor="#2F2F320D"
                borderColor="linear-gradient(135deg, #FF7E5F, #FEB47B)"
            />
            <Flex borderTop={" 1px solid #3A3C4221"}
                mt={'14px'}
                h='70vh'
                justifyContent="center"
                alignItems="center"
            >
                <Image boxSize={'sm'} src={"/images/Food.svg"} alt="Icon" />
            </Flex>
            <Flex bg={'#FFFFFF'}
                zIndex={100000000000}
                direction={'column'}
                position="fixed"
                pt={'60px'}
                pb={'45px'}
                borderRadius="32px 32px 0px 0px"
                bottom="0"
                w={'full'}>
                <VStack align={'center'}>

                    <Heading noOfLines={2}
                        textAlign={"center"} mx={'70px'}
                        fontSize={'23px'}
                        fontWeight={500}
                        lineHeight={'28.29px'}>
                        Best michelin star restaurants in Bangkok...
                    </Heading>
                    <Image src={"/images/wave.png"} alt="Icon" />

                    <Text
                        fontSize={'20px'}
                        fontWeight={500}
                        color={'#16181BA1'
                        }
                        textAlign={'center'}
                    >5 sec...</Text>
                    <HStack spacing={'65px'}>
                        <Button bg='inherit' variant='link' onClick={() => { }}>
                            <img src={'/icons/cross_filled.svg'} alt='cross' />
                        </Button>
                        <Button bg='inherit' variant='link' onClick={() => { }}>
                            <img src={'/icons/greenMic.svg'} alt='back' />
                        </Button>
                        <Button bg='inherit' variant='link' onClick={() => { }}>
                            <img src={'/icons/send.svg'} alt='cross' />
                        </Button>

                    </HStack>
                </VStack>
            </Flex>
        </Box>

    );
}
export default VoiceAdvisor;