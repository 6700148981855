import { Box, Flex, Text, SkeletonText, HStack, Heading, VStack, Image } from "@chakra-ui/react";
import Header from "./Header";
import InputField from "./InputField";
import { useContext, useEffect, useRef } from "react";
import { AppContext } from "../../context/AppContext";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import ChatCard from "./ChatCard";
import { useNavigate } from "react-router-dom";
import { fetchEntityDetails } from "../../services/apiService";
import { useLoading } from "../../context/LoadingContext";

const Chat: React.FC = () => {
  const navigate = useNavigate();
  const context: any = useContext(AppContext);
  const {
    chatEntries,
    isLoading,
    title,
    setPreviousChatLoading,
    setChatEntries,
    setTitle,
    setCurrChatId,
    setOnBehalf,
    entitesDetail,
    setEntitesDetail,
    setInfluencerData,
    userDetail

  } = context;

  const { setIsLoading } = useLoading();
  const bottomRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Scroll to bottom when chatEntries change
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [chatEntries]);

  const settings = {
    dots: false,
    infinite: false,
    speed: 200,
    centerMode: true,
    slidesToShow: 1.2,
    centerPadding: "15px",
    arrows: false,
    autoplay: false,
  };

  const handleNewChatButton = () => {
    setChatEntries([]);
    setTitle("");
    setCurrChatId("");
    setOnBehalf({});
    navigate("/chat");
  };

  const handleChatButton = () => {
    navigate("/savedConversation");
  };

  const handleCardClick = async (clickedIndex: number, clickedTitle: string, allEntities: { title: string; image: string }[]) => {
    const secondLastMessage = chatEntries[chatEntries.length - 2]?.message;
    const lastMessage = chatEntries[chatEntries.length - 1]?.message;
    const contxt = {
      query: secondLastMessage,
      answer: lastMessage
    }
    // Fetch entity details for the clicked title
    console.log("clickedTitle is:", clickedTitle);

    const curr = entitesDetail;


    if (curr.length > 0) {
      const allMatch = allEntities.every(
        (entity: { title: any; }, index: number) => {
          console.log("entity title ", entity.title);
          console.log("curr title index ", curr[index]?.title);

          return (entity.title === curr[index]?.title)
        }
      );
      console.log("Entity detail in chat is ", entitesDetail);

      if (allMatch) {
        console.log('matched')
        const initialEntityDetail = allEntities.find(
          (entity) => entity.title === clickedTitle
        );
        setPreviousChatLoading(true);

        navigate("/story-carousel", {
          state: {
            clickedIndex: clickedIndex,
            initialEntityDetail: initialEntityDetail,
            allEntities: allEntities, // pass the array with title and image

          },
        });
      }
      else {
        console.log("not matched")
        setInfluencerData([]);
        setIsLoading(true);
        setEntitesDetail([]);
        try {
          console.log('userDetail not matched', userDetail);

          const initialEntityDetail = await fetchEntityDetails(clickedTitle, userDetail, contxt);

          // console.log("response is:", JSON.stringify(initialEntityDetail.data));
          setPreviousChatLoading(true);

          navigate("/story-carousel", {
            state: {
              clickedIndex: clickedIndex,
              initialEntityDetail: initialEntityDetail.data.entity,
              allEntities: allEntities, // pass the array with title and image
              context: contxt

            },
          });
        }


        catch (error) {
          console.error("Error fetching entity details:", error);
        }
        finally {
          setIsLoading(false);
        }
      }
    }
    else {
      console.log("new one");
      setIsLoading(true);
      try {
        setInfluencerData([]);
        setEntitesDetail([]);
        console.log('userDetail new one', userDetail);

        const initialEntityDetail = await fetchEntityDetails(clickedTitle, userDetail, contxt);

        // console.log("response is:", JSON.stringify(initialEntityDetail.data));
        setPreviousChatLoading(true);

        navigate("/story-carousel", {
          state: {
            clickedIndex: clickedIndex,
            initialEntityDetail: initialEntityDetail.data.entity,
            allEntities: allEntities, // pass the array with title and image
            context: contxt

          },
        });
      }


      catch (error) {
        console.error("Error fetching entity details:", error);
      }
      finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <Flex bg={"#FFFFFF"} h="100vh">
      {/* Header remains fixed */}
      <Box
        bg={"#FFFFFF"}
        position={"fixed"}
        top={0}
        left={0}
        w={"100vw"}
        zIndex={5}
      >
        <Header
          backIconSrc="/icons/arrow_back.svg"
          chatIconSrc="/icons/chat_filled.svg"
          addIconSrc="/icons/plus_outline.svg"
          handleBackButton={() => {
            navigate(-1);
          }}
          chatButtonOnClick={handleChatButton}
          addButtonOnClick={handleNewChatButton}
          bgColor="#2F2F320D"
          borderColor="linear-gradient(135deg, #FF7E5F, #FEB47B)"
        />

        {/* Chat Topic Bar */}
        <Flex
          borderTop={"1px solid #3A3C4221"}
          mt={"5px"}
          pt={"8px"}
          pb={"7px"}
          pl={"18px"}
          boxShadow={"0px 7px 7px 0px #00000008"}
        >
          <Text
            fontSize={"11px"}
            fontFamily={"500"}
            letterSpacing={"-0.47px"}
            color={"#16181BA1"}
          >
            {title}
          </Text>
        </Flex>
      </Box>

      <Box bg={"#FFFFFF"} position={"fixed"} w="100vw" h="100vh">
        <Box
          flex={1}
          mt="70px"
          overflowY="auto"
          pt={4}
          pb={'12%'}
          mb={"10px"}
          maxH="calc(100vh - 21vh)"
        >
          <Box mt={"20px"}>
            {chatEntries.map(
              (
                entry: {
                  message: string;
                  type?: string;
                  role: string;
                  time: string;
                  entities?: [];
                  onBehalf?: {
                    imageUrl: string;
                    title: string;
                    subtitle: string;
                  };
                },
                index: number
              ) => (
                <Flex
                  justifyContent={
                    entry.role === "user" ? "flex-end" : "flex-start"
                  }
                  key={index} // Added key here
                  mb={2} // Margin between entries
                >
                  {isLoading &&
                    entry.role === "assistant" &&
                    entry.message === "..." ? (
                    <SkeletonText
                      height="40px"
                      width="40vw"
                      borderRadius="8px"
                      p={"10px"}
                      noOfLines={3}
                    />
                  ) : (
                    <Flex
                      direction={"column"}
                      maxW={"100vw"}
                      overflowX={"hidden"}
                      mr={"12px"}
                      ml={"12px"}
                    >
                      {/* For the Text */}

                      <Flex
                        justifyContent={
                          entry.role === "user" ? "flex-end" : "flex-start"
                        }>
                        <Box
                          borderRadius={"8px"}
                          maxW={"227px"}
                          mx={1}
                          bg={
                            entry.role === "assistant" ? "#E8E9FF" : "#E0F0EA"
                          }
                        >
                          {entry.onBehalf && (
                            <HStack spacing={0} borderRadius={"9px"} bg={"#F9FAFC"} h="72px" pr={5}>
                              <Image
                                src={entry.onBehalf.imageUrl}
                                alt={entry.onBehalf.title}
                                borderRadius={"12px 0px 0px 12px"}
                                objectFit={"cover"}
                                maxH={"72px"}
                              />
                              <Box py={"32px"}>
                                <VStack
                                  spacing={0}
                                  alignItems={"flex-start"}
                                  ml={"12px"}
                                  mt={0}
                                >
                                  <Heading
                                    fontSize="12px"
                                    letterSpacing={"-0.5px"}
                                    fontWeight={700}
                                    color={"#424242"}
                                  >
                                    {entry.onBehalf.title}
                                  </Heading>
                                  <Heading
                                    fontSize={"11px"}
                                    color={"#424242"}
                                    letterSpacing={"-0.5px"}
                                    fontWeight={400}
                                  >
                                    {entry.onBehalf.subtitle}
                                  </Heading>
                                </VStack>
                              </Box>
                            </HStack>)}

                          <Text
                            fontFamily={"Poppins"}
                            color={"#16181B"}
                            fontSize={"14px"}
                            py={"10px"}
                            px={"12px"}
                            lineHeight={"19px"}
                            fontWeight={700}
                          >
                            {entry.message}
                          </Text>
                        </Box>
                      </Flex>
                      {entry.type && entry.type === "TextR" && (
                        <Box pt="12px" pb={"12px"} h={"auto"}>
                          <Slider key={index} {...settings}>
                            {entry.entities?.map(
                              (entity: {
                                image: string; title: string
                              }, index: number) => (
                                <ChatCard
                                  key={index}
                                  imageSrc={entity.image}
                                  altText=""
                                  title={entity.title.replace(/<[^>]+>/g, "")}
                                  buttonText="View Details"
                                  onCardClick={() =>
                                    handleCardClick(
                                      index,
                                      entity.title,
                                      entry.entities?.map((h: { title: string; image: string }) => ({
                                        title: h.title,
                                        image: h.image,
                                      })) || []
                                    )
                                  }

                                />
                              )
                            )}
                          </Slider>
                        </Box>
                      )}
                      <Flex
                        justifyContent={
                          entry.role === "user" ? "flex-end" : "flex-start"
                        }
                      >
                        <Text
                          color={"#B5B5B5"}
                          fontSize={"11px"}
                          mt={1}
                          mx={1}
                          letterSpacing={"-0.47px"}
                        >
                          {entry.time}
                        </Text>
                      </Flex>
                    </Flex>
                  )}
                </Flex>
              )
            )}
            <div ref={bottomRef} />
          </Box>
        </Box>
      </Box>

      {/* Input Field */}
      <Box
        position={"fixed"}
        bottom={"10px"}
        bg={"white"}
        px={"31px"}
        py={3}
        w={"full"}
      >
        <InputField placeholder="Type here..." />
      </Box>
    </Flex>
  );
};

export default Chat;
