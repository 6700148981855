import {
	Flex,
	Heading,
} from '@chakra-ui/react';
import { VideoHighlights } from './VideoHighlights';

interface InfLocationDetailProps {
	influencerData: any;
}
const InfLocationDetail: React.FC<InfLocationDetailProps> = ({
	
	influencerData,
}) => {
	// console.log("One influencer ", JSON.stringify(influencerData));
	return (
		<Flex flexDir={'column'} minH={'100%'} overflowX={'hidden'} p={2} px={4}>
			{/* Video Highlights */}
			{influencerData?.['highlights']?.length ? (
				<><Heading
					
					fontFamily='Poppins'
					fontSize='20px'
					fontWeight='500'
					lineHeight='18px'
					letterSpacing='-0.30000001192092896px'
					textAlign='left'
					color={'#FFFFFFA1'}
					mb={'32px'}>
					Video Highlights
				</Heading><VideoHighlights highlights={influencerData?.['highlights']} /></>
			) : (
				<Heading
				
					fontFamily='Poppins'
					fontSize='20px'
					fontWeight='500'
					lineHeight='18px'
					letterSpacing='-0.30000001192092896px'
					textAlign='left'
					color={'#FFFFFFA1'}
					mb={'32px'}>
					Video Highlights Coming soon ...
				</Heading>
			)}
		</Flex>
	);
};
export default InfLocationDetail;
