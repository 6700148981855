import { Box, IconButton, Image } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import InfluencerVideo1 from "./InfluencerVideo1";
import { useEffect, useState } from "react";
import useAxios from "../../hooks/useAxios";

const BackButton: React.FC = () => {
	const navigate = useNavigate();

	// const location = useLocation();
	// const prevLocation = useRef(null);

	// Update the ref with the current location on each render
	// useEffect(() => {
	// 	//@ts-ignore
	// 	prevLocation.current = location.pathname;
	// }, [location]);

	return (
		<IconButton
			position='fixed'
			aria-label='back'
			top='4%'
			left='3%'
			zIndex={11}
			icon={<Image src='/icons/Back_Icon_White.svg' alt='back' />}
			variant='ghost'
			size='lg'
			onClick={() => {
				// Check if the previous location is defined and not on the home page
				// if (currentIndex) {
				// {let index = -1 * (currentIndex + 1);
				// navigate(index);}
				navigate(-1);
				// }
				//@ts-ignore
				// else if (prevLocation.current && !prevLocation.current.includes('/')) {
				// 	navigate(prevLocation.current);
				// }
				//  else {
				// 	navigate('/'); // Navigate to 'home' if there's no previous location or it's on 'home'
				// }
			}}
			borderRadius='full'
			dropShadow='0px 4px 10px rgba(0, 0, 0, 0.6)'
			_hover={{ bg: 'transparent' }}
			_active={{ bg: 'transparent' }}
			_focus={{ boxShadow: 'none' }}
		/>
	);
};

const InfluencersVideo: React.FC = () => {

	const { fetchData } = useAxios();
	const [showBackButton, setShowBackButton] = useState(true);

	const [influencers, setInfluencers] = useState<any>({
		channelName: '',
		chapter_segment: '[]',
		highlights: [],
		score: 0.0,
		source: '',
		tags: [],
		video_url: '',
		preview_image: '',
		video_play_url: {
			title: '',
			is_audio: false,
			author: '',
			source: '',
			previewImage: '',
			media_url: '',
		},
	});
	const [currentIndex, setCurrentIndex] = useState(0);
	const location = useLocation();
	const clicked = location.state?.clicked ?? "";

	const influencerDataState = location.state?.influencerData ?? {
		channelName: '',
		chapter_segment: '[]',
		highlights: [],
		score: 0.0,
		source: '',
		tags: [],
		video_url: '',
		video_play_url: {
			title: '',
			is_audio: false,
			author: '',
			source: '',
			previewImage: '',
			media_url: '',
		},
	};

	const queryParams = new URLSearchParams(location.search);
	const link = queryParams.get('link');

	const fetchVideoDetail = async () => {
		const { data, statusCode, error } = await fetchData({
			method: 'POST',
			url: 'ai/video-link-data',
			options: {
				data: {
					videos: [link],
				},
				headers: { 'Content-Type': 'application/json' },
			},
			auth: false,
		});

		if (statusCode === 200) {
			if (data && data.videos) {
				setInfluencers(data.videos);
			}
		} else {
			console.log(error);
		}
	};
	useEffect(() => {
		if (!link) {
			const index = influencerDataState.findIndex((item: any) => item?.video_url === clicked?.video_url);
			setCurrentIndex(index !== -1 ? index : 0);
		}
		// eslint-disable-next-line
	}, [influencerDataState]);

	useEffect(() => {
		const handleScroll = () => {
			const scrollPosition = window.scrollY;
			const viewportHeight = 60;

			// console.log(`Scroll Position: ${scrollPosition}`);
			// console.log(`Viewport Height: ${viewportHeight}`);

			if (scrollPosition > viewportHeight) {
				console.log('Hiding back button');
				setShowBackButton(false);
			} else {
				console.log('Showing back button');
				setShowBackButton(true);
			}
		};

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	useEffect(() => {
		// alert(link);
		if (link) {
			console.log("I am here111111")
			fetchVideoDetail();
			setCurrentIndex(0);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [link]);


	return (
		<Box>
			{showBackButton && <BackButton />}
			<InfluencerVideo1
				influencers={link ? influencers : influencerDataState}
				currentIndex={currentIndex}
				isLink={link === null ? false : true}
				handleNext={() => {
					setCurrentIndex((prev) => (prev + 1) % influencerDataState.length);
				}}
				handlePrevious={() => {
					setCurrentIndex((prev) => (prev - 1 + influencerDataState.length) % influencerDataState.length)
				}}

			/>
		</Box>

	)

};
export default InfluencersVideo;